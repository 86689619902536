import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PageTitle from '../components/page_title'
import PageContents from '../components/page_contents'
import * as style from '../styles/contact.module.css'
import PhoneIcon from '../images/svg/phone-icon.svg'
import MailIcon from '../images/svg/mail-icon.svg'
import MapMarkerIcon from '../images/svg/map-marker-icon.svg'

export default function Contact() {
  return (
    <Layout showFooter={true}>
      <Seo
        title='Contact'
        description='Contact the Linden Law Group by phone or email, or in person at their office in downtown Colorado Springs. Click here to get started.'
      />

      <PageContents>
        <PageTitle>Contact Us</PageTitle>

        <div className={`${style.main} wide_content`}>
          <div className={style.info}>
            <div className={style.method}>
              <PhoneIcon className={style.icon} />
              <span>(719) 955-0078</span>
            </div>

            <div className={style.method}>
              <MailIcon className={style.icon} />
              <span>vince@lindenlawgroup.com</span>
            </div>

            <div className={style.method}>
              <MailIcon className={style.icon} />
              <span>mary@lindenlawgroup.com</span>
            </div>

            <div className={style.address_wrapper}>
              <MapMarkerIcon className={style.icon} />

              <div className={style.address}>
                <span>111 S Tejon St, Suite 202</span>
                <span>Colorado Springs, CO 80903</span>
              </div>
            </div>

            <a
              href='https://www.google.com/maps/dir//111+S+Tejon+St,+Colorado+Springs,+CO+80903'
              className={style.directions}
              target='_blank'
              rel='noopener noreferrer'
            >
              Get Directions
            </a>
          </div>

          <div className={style.map_wrapper}>
            <iframe
              title='map'
              frameBorder='0'
              className={style.map}
              src={
                'https://www.google.com/maps/embed/v1/place?key=AIzaSyCNvSwOJHrIYXkf926ATIkoC8GndLbUeH8&q=111+S+Tejon+St,+Colorado+Springs,+CO+80903'
              }
              allowFullScreen
            />
          </div>
        </div>
      </PageContents>
    </Layout>
  )
}
